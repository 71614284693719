import React from 'react'

const Policy = () => {
  return (
    <div style={{height:"50vh"}}>
      <div className="container">
      <h1 className='text-center my-5 text-light'>Bütün hüquqlar Webluna Tərəfindən qorunur!</h1>
       
      </div>
    </div>
  )
}

export default Policy