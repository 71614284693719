

interface ProjectCardPropType{
  title:string,
  domain:string,
  photo:string
}

const ProjectSingleCard = (props:ProjectCardPropType) => {
  return (
    <div className="col-12 col-sm-12 col-md-6" data-aos="fade-up">
    <div className="card ">
      <img
        src={props.photo}
        className="card-img-top"
      />
      <div className="card-desc h-100">
      <h4 className="text-light mb-4">{props.title}</h4>
        <div>
        <a target="_blank" href={props.photo}><button className="btn btn-success me-4">Yaxından bax</button></a>
        <a target="_blank"  href={props.domain}><button className="btn btn-light">Səhifəyə bax</button></a>
        </div>
      </div>
    </div>
  </div>
  )
}

export default ProjectSingleCard

