import { Link } from "react-router-dom";
import logo from "../assets/img/logo-1.png";
const Footer = () => {
  return (
    <>
      <div className="footer-skew ">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-5 mt-5 footer-left">
              <div className="footer-logo">
                <img
                  className="mt-5"
                  width={140}
                  src="https://webluna.az/cdn/webluna/photo/logo.png"
                  alt={logo}
                />
              </div>
              <p>
                Biz 2021-ci ildən etibarən öz müştərlərimiz üçün <br />
                çox saylı veb layihələr hazırlayaraq onların razılığını və
                etibarını qazanmışıq!
              </p>

             
            </div>
            <div className="col-12 col-sm-12 col-md-2">
              {/* <div className="line-footer "></div> */}
            </div>
            <div className="col-12 col-sm-12 col-md-5 footer-right">
              <h1>Bizə qoşulun</h1>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Emailinizi daxil edin"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                />
                <span
                  className="input-group-text btn btn-outline-light"
                  id="basic-addon2"
                >
                  Göndər
                </span>
              </div>
              <div className="bottom-line my-5"></div>
              <div className="footer-nav">
                <ul className="d-flex flex-wrap">
                 
                  
                  <li className="me-5">
                    <Link className="text-light" to="/">
                      Əsas səhifə
                    </Link>
                  </li>
               
                  <li className="me-5">
                    <Link className="text-light" to="/">
                      Əlaqə
                    </Link>
                  </li>
                  <li className="me-5">
                    <Link className="text-light" to="/templateplatform">
                      Layihə Kataloqu
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
       
      </div>
      <p className="text-light text-center p-5">
                Copyright ©{new Date().getFullYear()} |{" "}
                <Link className="text-light" to="policy">
                  Gizlilik Siyasəti
                </Link>
              </p>
    </>
  );
};

export default Footer;
