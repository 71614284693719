import { useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Footer from '../components/Footer'
import Nav from '../components/Nav'
import About from '../pages/About'
import Contact from '../pages/Contact'
import Home from '../pages/Home'
import NotFoundPage from '../pages/NotFoundPage'
import Policy from '../pages/Policy'
import Projects from '../pages/Projects'
import MainPage from '../pages/templateplatform/MainPage'
import AOS from "aos";
import "aos/dist/aos.css";

const App = () => {
  useEffect(()=>{
    AOS.init();
  },[])
  return (
    <BrowserRouter>
    <Nav/>
      <Routes>
        <Route path='/' element={<Home/>}></Route>
        <Route path='/about' element={<About/>}></Route>
        <Route path='/projects' element={<Projects/>}></Route>
        <Route path='/templateplatform' element={<MainPage/>}></Route>
        <Route path='/policy' element={<Policy/>}></Route>
        <Route path='/contact' element={<Contact/>}></Route>
        <Route path='*' element={<NotFoundPage/>}></Route>
      </Routes>
    <Footer />
    </BrowserRouter>
  )
}

export default App