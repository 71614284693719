import { Container, Row } from "react-bootstrap"
import { Helmet } from "react-helmet"
import SingleThemeCard from "../../components/themeplatform/cards/SingleThemeCard"
import themedata from "../../data/themedata"

const MainPage = () => {
  return (
    <Container >
      <Helmet>
      <title>Layihə Kataloqu</title>
      <meta name="description" content="Burada siz öz biznesiniz üçün veb dizayn, səhifə və sayt seçə bilərsiz."/>
    </Helmet>
      <h1 className="text-center text-light my-5">Layihə Kataloqu</h1>
      <Row className="g-5">
        {themedata.map((item:any,c:number)=>{
          return <SingleThemeCard key={c} count={c} title={item.title} desc={item.desc} img={item.img} />
        })}
        
      </Row>

      </Container>
  )
}

export default MainPage