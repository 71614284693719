interface themedataType {
  id: number;
  title: string;
  category: string;
  img: string;
  desc: string;
}
const themedata: themedataType[] = [
  {
    id: 1,
    title: "Tikinti üçün dizayn",
    category: "construction",
    img: "https://webluna.az/cdn/webluna/photo/themes/buildtheme.png",
    desc: "Bu dizayn tikinti şirkətləti üçün nəzərdə tutulub.",
  },
  {
    id: 2,
    title: "Təmir şirkətləri üçün dizayn",
    category: "modern_design",
    img: "https://webluna.az/cdn/webluna/photo/themes/moderntheme.png",
    desc: "Bu dizayn interyer dizayn və ev təmiri ilə əlaqəli şirkətləti üçün nəzərdə tutulub.",
  },
  {
    id: 3,
    title: "Təhsil sahəsi üçün dizayn",
    category: "education",
    img: "https://webluna.az/cdn/webluna/photo/themes/edutheme.png",
    desc: "Bu dizayn təhsil sahəsi ilə əlaqəli şirkətlər ,habelə, məktəblər, kolleclər və kurslar istifdə edə bilər.",
  },
  {
    id: 4,
    title: "Zinət əşyalarının satışı üçün dizayn",
    category: "jewelry",
    img: "https://webluna.az/cdn/webluna/photo/themes/jewelrytheme.png",
    desc: "Bu dizayn zinət əşyalarının satışını reallaşdıran şirkətlər üçün hazırlanıb.",
  },
  {
    id: 5,
    title: "Xəbər saytı üçün dizayn",
    category: "news",
    img: "https://webluna.az/cdn/webluna/photo/themes/newstheme.png",
    desc: "Bu dizayn xəbər agentlikləri və şirkətləti üçün nəzərdə tutulub.",
  },
];

export default themedata;
