import { Col, Container, Row } from "react-bootstrap"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { Helmet } from "react-helmet";

const Contact = () => {
  const MySwal = withReactContent(Swal);
  const sendEmail = (e:any) => {
    e.preventDefault();

    emailjs.sendForm('service_n2nuccv', 'template_djhmafn', e.target, 'cVBf7nQwR9JgBlZoL')
      .then((result:any) => {
          console.log(result.text);
      }, (error:any) => {
          console.log(error.text);
      });
  };
  
  return (
    <Container style={{height:"auto"}}>
      <Helmet>
      <title>Əlaqə</title>
      <meta name="description" content="Bizimlə əlaqə formu."/>
    </Helmet>
       <h1 className="text-light text-center my-5">Bizimlə Əlaqə</h1>
      

          <div className="d-flex align-items-center justify-content-center">
            <Col md={6}>
            <Form  onSubmit={sendEmail}>
      <Form.Group className="mb-3" data-aos="fade-right">
        <Form.Label className="text-light">Adınız</Form.Label>
        <Form.Control type="text" placeholder="Adınızı daxil edin" name="name" />
      </Form.Group>

      <Form.Group className="mb-3" data-aos="fade-left">
        <Form.Label className="text-light">Soyadınız</Form.Label>
        <Form.Control type="text" placeholder="Soyadınızı daxil edin" name="surname"/>
      </Form.Group>

      <Form.Group className="mb-3" data-aos="fade-right">
        <Form.Label className="text-light">Əlaqə nömrəsi</Form.Label>
        <Form.Control type="tel" placeholder="Əlaqə nömrənizi daxil edin" name="phone"/>
      </Form.Group>

      <Form.Group className="mb-3" data-aos="fade-left">
        <Form.Label className="text-light">Email</Form.Label>
        <Form.Control type="email" placeholder="Emailinizi daxil edin" name="email"/>
      </Form.Group>


    

      <Form.Group className="mb-3" data-aos="fade-right">
        <Form.Label className="text-light">İsmarıc</Form.Label>
        <Form.Control type="text" placeholder="İsmarıcınızı daxil edin" name="message"/>
      </Form.Group>
 
      <Button variant="success" type="submit"  onClick={()=>{
             MySwal.fire({
              title: <strong>İsmariciniz uğurla göndərildi!</strong>,
              html: <i></i>,
              icon: 'success'
            })
          }}>
        Göndər
      </Button>
      
    </Form>
            </Col>
          </div>
          <Row className="my-5 " >
            <Col>
                <h4 className="text-light">Ünvan: Azərbaycan, Bakı şəh</h4>
            </Col>
            <Col>
                <h4 className="text-light">Əlaqə nömrəsi: <a className="text-light" href="tel:+994703479727">+994703479727</a></h4>
            </Col>
            <Col>
                <h4 className="text-light">Email: <a className="text-light" href="mailto:info@webluna.az">info@webluna.az</a></h4>
            </Col>
        </Row>
    </Container>
  )
}

export default Contact