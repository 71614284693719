import { Button, Col } from "react-bootstrap"

interface themeCardType{
  count:number;
  title: string;
  img: string;
  desc: string;

}



const SingleThemeCard = (props:themeCardType) => {
  return (
      <Col sm={6} md={4} data-aos="fade-up">
      
      <div className="card" >
  <img src={props.img} className="card-img-top" alt={props.title} />
  <div className="card-body">
    <h5 className="card-title">{props.title}</h5>
    <p className="card-text">{props.desc}</p>
        <div className="accordion" id="accordionExample">
   

        <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapseOne${props.count}`} aria-expanded="false" aria-controls={`collapseOne${props.count}`}>
            Sadəcə Dizayn
            </button>
            </h2>
            <div id={`collapseOne${props.count}`} className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
            <div className="accordion-body">
              <Button variant="secondary">Dizayna Bax</Button>
              <Button className="ms-4" variant="success">Dizaynı Al</Button>
            </div>
            </div>
        </div>
        <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapseTwo${props.count}`} aria-expanded="false" aria-controls={`collapseTwo${props.count}`}>
                Görünən hissə
            </button>
            </h2>
            <div id={`collapseTwo${props.count}`} className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
            <div className="accordion-body">
            <Button className="ms-4" variant="warning">Görünən hissəni Al</Button>
            </div>
            </div>
        </div>
        <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapseThree${props.count}`} aria-expanded="false" aria-controls={`collapseThree${props.count}`}>
                Bütün Layihə
            </button>
            </h2>
            <div id={`collapseThree${props.count}`} className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
            <div className="accordion-body">
            <Button className="ms-4" variant="danger">Bütün Layihəni Al</Button>
            </div>
            </div>
        </div>
        </div>
  </div>
</div>
      </Col>
  )
}


export default SingleThemeCard