import SingleCard from "../components/home/cards/ProjectSingleCard";
import projectsdata from "../data/projectsdata";

const Projects = () => {
  return (
    <div id="projects" className="projects">
      <h1 className="text-center text-light my-5 ">Hazırladığımız bəzi layihələr</h1>
      <div className="container ">
        <div className="row g-5">

        
      {projectsdata.map((item:any,c:number)=>(
        
        <SingleCard photo={item.img} title={item.title} key={c} domain={item.domain}/>
      ))}

         
        




        </div>
      </div>
    </div>
  );
};

export default Projects;
