import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { useState } from 'react';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
export const BarChart: React.FC<{}> = () => {
  const labels = ['React JS', 'Node JS', 'Laravel', 'JavaScript', 'PHP', ];
  const [data, setData] = useState({
    labels: labels,
    datasets: [{
      label: 'Istifadə olunma aktivliyi',
      data: [90, 59, 50, 70, 90,],
      backgroundColor: [
        'rgb(0, 195, 132)'
      ],
      borderColor: [
        'rgb(0, 0, 0)'
      ],
      borderWidth: 1
    }]
  });

  return <Bar data={data} />;
};