interface projectsdataType {
  id: number;
  title: string;
  domain: string;
  img: string;
}

const projectsdata:projectsdataType[] = [
  {
    id: 1,
    title: "Bolt Center",
    domain: "https://boltcenter.az",
    img: "https://webluna.az/cdn/webluna/photo/projects/boltcenter.png",
  },
  {
    id: 2,
    title: "AKM Group",
    domain: "https://akmgroup.az",
    img: "https://webluna.az/cdn/webluna/photo/projects/akmgroup.png",
  },
  {
    id: 3,
    title: "Kreart",
    domain: "https://kreart.az",
    img: "https://webluna.az/cdn/webluna/photo/projects/kreart.png",
  },
  {
    id: 4,
    title: "Toptul",
    domain: "https://toptul.az",
    img: "https://webluna.az/cdn/webluna/photo/projects/toptul.png",
  },
  {
    id: 5,
    title: "Start IV",
    domain: "https://startiv.az",
    img: "https://webluna.az/cdn/webluna/photo/projects/startiv.png",
  },
  {
    id: 6,
    title: "Global Translation Group",
    domain: "https://globaltranslationsgroup.com/",
    img: "https://webluna.az/cdn/webluna/photo/projects/globaltransilation.png",
  },
  {
    id: 7,
    title: "AliSahib MMC",
    domain: "https://alisahib.com",
    img: "https://webluna.az/cdn/webluna/photo/projects/alisahib.png",
  },
  {
    id: 8,
    title: "Ata Automative",
    domain: "#",
    img: "https://webluna.az/cdn/webluna/photo/projects/ataautomative.png",
  },
  {
    id: 9,
    title: "Ucuzapar",
    domain: "#",
    img: "https://webluna.az/cdn/webluna/photo/projects/ucuzapar.png",
  },
];

export default projectsdata;
