import { Link, NavLink } from 'react-router-dom'
import 'reactjs-popup/dist/index.css';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';

const Nav = () => {
  const MySwal = withReactContent(Swal);
 
  return (
   <div className="container-fluid">
     <nav className="navbar navbar-expand-lg navbar-dark navbar-default navbar-fixed-top">
    <div className="container-fluid">
      <Link className="ms-5 navbar-brand" to="/"><img width={140} src="https://webluna.az/cdn/webluna/photo/logo.png" alt="" /></Link>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon" />
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
          <li className="nav-item">
            <Link className="nav-link ms-5"   to="/">Əsas Səhifə</Link>
          </li>
          <li className="nav-item ms-5">
            <NavLink className="nav-link" to="/about">Haqqımızda</NavLink>
          </li>

          <li className="nav-item ms-5">
            <NavLink className="nav-link" to="/projects">Layihələrimiz</NavLink>
          </li>

          <li className="nav-item mx-5">
            <NavLink to="/contact" className="nav-link" >Əlaqə</NavLink>
          </li>
         
        </ul>
        <div className="d-flex me-5" >
          <Link onClick={()=>{
             MySwal.fire({
              title: <strong>Xoş gəlmisiniz!</strong>,
              html: <i>Gün gündən zənginləşən layihə galeriyamızada öz biznesinizə aid layihələr tapa bilərsiz.</i>,
              icon: 'info'
            })
          }} className="btn btn-outline-success" to="templateplatform">Layihə Kataloqu</Link>
        </div>
      </div>
    </div>
  </nav>
   </div>
  
  )
}

export default Nav