import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

const About = () => {
  return (
    <div className='container'>
    <Helmet>
      <title>Haqqımızda</title>
      <meta name="description" content="Biz veb üçün çalışan bir qurumuq!"/>
    </Helmet>
      <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
        <div className="col-10 col-sm-8 col-lg-6" data-aos="fade-left">
          <img src="https://webluna.az/cdn/webluna/photo/about.jpg" className="shadow-lg rounded-4 d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width={700} height={500} loading="lazy" />
        </div>
        <div className="col-lg-6" data-aos="fade-right">
          <h1 className="display-5 fw-bold lh-1 mb-3 text-light">Haqqımızda</h1>
          <p className="lead text-light">  Biz veb üçün çalışan bir qurumuq!
            
            Siz bizim platforma ilə həm veb layihələrinizi sıfırdan hazırlatdıra bilər və ya
            bizim layihələr kataloqumuzdan öz biznesinizə uyğun dizayn və ya layihəni dərhal
            tapa bilərsiz. Bizim xidmətimiz hər bir biznesə uyğun olmağı ilə yanaşı
            yeni biznes və start up qurmağa  başlayanlara da xitab edir.
       
            Siz bizim platforma vasitəsi ilə heç bir texniki məsələni düşünmədən bir başa olaraq veb sayt sahibi olacaqsız.
            Biz sizin üçün veb layihənin dizayını texniki tərəfini hazırlayır veb bazasına yükləyir və layihəni optimizasiya edirik.
            Sizə qalan isə veb layihənizi seyr etmək  və biznesinizin keyfiyyətini yüksəltmək olur.
            Kataloqumuzda bu günün trendinə uyğun dizanlar, veb layihələr  və veb tətbiqlər
            axtara bilərsiz.
            Əgər sizdə biznesinizin inkişafını sürətləndirmək istəyirsinizsə elə indi müraciət edin.</p>
          <div className="d-grid gap-2 d-md-flex justify-content-md-start">
            <Link type="button" to="/contact" className="btn btn-success btn-lg px-4 me-md-2">Əlaqə</Link>
            <Link type="button" to="/templateplatform" className="btn btn-outline-light btn-lg px-4">Kataloq</Link>
          </div>
        </div>
      </div>



      
  
    </div>
  )
}

export default About