import leftHero from "../assets/img/hero-left.png";
import skewLine from "../assets/img/skew-line.png";
import { BarChart } from "../components/home/chartjs/BarChart";
import { Button, Container, Row } from "react-bootstrap";
import projectsdata from "../data/projectsdata";
import { Link } from "react-router-dom";
import {Helmet} from 'react-helmet';
const Home: React.FunctionComponent = () => {
  return (
    <>

    <Helmet>
      <title>Əsas səhifə</title>
      <meta name="description" content="Webluna | veb səhifələrinin satışı"/>
    </Helmet>
      <div className="hero">
        <div className="container">
          <div className="row ">
            <div className="col-12 col-sm-12 col-md-6" data-aos="fade-up">
              <h1  className=" mt-5 text-light">
                Salam, Webluna'ya <br /> xoş gəldiniz! <br />
              </h1>
              <div className="skew-line my-5">
                <img src={skewLine} alt={skewLine} />
              </div>
              <p className="col-7 text-light ">
                Biz 2021-ci ildən etibarən öz müştərlərimiz üçün <br />
                çox saylı veb layihələr hazırlayaraq onların razılığını və
                etibarını qazanmışıq!
                <br />
                Əgər siz də biznesinizin inkişafı üçün lazım olan veb sayt və
                tətbiqə ehiyacınız varsa <br />
                Bizə müraciət edə bilərsiz.
              </p>
            </div>
            <div className="col-12 col-sm-12 col-md-6">
              <img
              data-aos="fade-up"
                src="https://webluna.az/cdn/webluna/photo/hero-photo.png"
                className="greyscale"
                alt={leftHero}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container py-5 mt-5">
        <div className="row g-5">
          <div className="col-12 col-sm-6 col-md-4">
            <BarChart />
          </div>
          <div className="col-12 col-sm-6 col-md-8" data-aos="fade-up">
            <h1 className="text-center text-light">
              İstifadə etdiyimiz texnologiyalar
              <br />
            </h1>
            <p className="mt-5 text-center text-light">
              Biz hər yeni ildə trend olan texnologiyları araşdırıb <br />
              onların nə dərəcədə səmərəli olmasını müəyyən edirik! <br />
              Bu da hər layihə üçün uğun texnologiyanın təyin olunmasında <br />
              böyük rol oynayır!
            </p>
          </div>
        </div>
      </div>

      <div className="skew-box">
        <div className="container">
          <div className="row ">
            <div className="col-12 col-sm-12 col-md-6" data-aos="fade-down">
              <h1 className="ms-5 mt-5 text-light">
                Niyə bizi <br /> Seçməlisiz? <br />
                Çünki...
              </h1>
              <div className="skew-line my-5">
                <img src={skewLine} alt={skewLine} />
              </div>
              <p className="col-7 text-light h5 ">
                Biz sizə heç bir vaxt itkisi olmadan layihələri sürətli və hazır
                şəkildə veririk. Bizdə layihələr öncədən hazır olur, bu da vaxta
                qənaət deməkdir!
              </p>
            </div>
            <div className="col-12 col-sm-12 col-md-6">
              <img
                src="https://webluna.az/cdn/webluna/photo/propcard.png"
                className="skew-right"
                alt={leftHero}
              />
            </div>
          </div>
        </div>
      </div>

      <Container>
        <h1 className="text-center text-light my-5">
          Hazırladığımız bəzi layihələr
        </h1>
        <Row>
          {projectsdata.slice(0, 2).map((item: any, c: number) => (
            <div key={c} className="col-12 col-sm-12 col-md-6 mb-5" data-aos="fade-up">
              <div className="card ">
                <img src={item.img} className="card-img-top" />
              </div>
            </div>
          ))}
        </Row>
        <p className="text-center my-5">
          <Link  to="/projects"  onClick={() => {
                                      window.scroll({
                                        top: 0,
                                        left: 0,
                                        behavior: "smooth",
                                      });
                                      }}>
            <Button variant="success">Daha cox</Button>
          </Link>
        </p>
      </Container>
    </>
  );
};

export default Home;
