
import { Link } from 'react-router-dom'

const NotFoundPage = () => {
  return (
    <div style={{height:"50vh"}} className="d-flex align-items-center justify-content-center flex-column">
        <h1 style={{fontSize:"100px"}} className=" text-light">404 - Not Found</h1>
        <Link to="/" className='btn btn-outline-light'>Əsas səhifəyə qayıt</Link>
    </div>
  )
}

export default NotFoundPage